import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { Hotspot } from 'src/app/domain/experience/place/hotspot';

@Pipe({
  name: 'typePipe'
})

@Injectable()
export class TypePipe implements PipeTransform {
  transform(hotspot: Hotspot, args?): string {
    if (hotspot.type != null) {
      if (hotspot.type.type != null) {
        return hotspot.type.type.name;
      }
      else {
        return hotspot.type.name;
      }
    }
  }
}
