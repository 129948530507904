/* eslint-disable @angular-eslint/contextual-lifecycle */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Response } from 'src/app/domain/base/response';
import { File } from 'src/app/domain/media/file/file';
import { Media } from 'src/app/domain/media/media';
import { MediaDto } from 'src/app/dto/yon/media/response/media.dto';
import { FileProvider } from 'src/app/providers/media/file/file.provider';

const WAIT_TIME = 1000;

@Injectable({ providedIn: 'root' })
export class UploadManager {

  public queue: UploadObject[];
  public inProgress = false;
  public uploadSubject = new Subject();
  public uploadFinishedSubject = new Subject();

  constructor(private fileProvider: FileProvider) {
    this.queue = new Array();

    this.uploadSubject.subscribe(
      () => {
        this.inProgress = true;
        this.upload();
      }
    );
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.uploadSubject.unsubscribe();
  }

  /**
   * add files to queue
   *
   * @param files
   */
  addQueue(files: FileList, directoryId: string) {
    const fileList = Array.from(files);
    fileList.forEach(file => {
      this.queue.push(new UploadObject(directoryId, file));
    });
    this.process();
  }

  /**
   * control whether is in progress if in progress
   * then wait constant time and call itself again
   * or trigger upload observable
   */
  process() {
    if (this.inProgress) {
      setTimeout(() => {
        this.process();
      }, WAIT_TIME);
    }
    else {
      this.uploadSubject.next();
    }
  }

  upload() {
    if (this.queue.length > 0) {

      this.queue[0].started = true;

      this.fileProvider.create(this.queue[0].file, this.queue[0].directoryId).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.queue[0].progress = event.loaded / event.total;
          this.queue[0].progressInt = Math.round((event.loaded / event.total) * 100);
          console.log(this.queue[0].file.name, ': ', this.queue[0].progressInt);
        }
        else if (event instanceof HttpResponse) {

          const file: File = (JSON.parse(event.body as string) as Response).data;
          const media: Media = file;

          this.uploadFinishedSubject.next(media);

          this.queue.shift();

          if (this.queue.length > 0) { this.upload(); }
          else { this.inProgress = false; }
        }
      });
    }
    else {
      this.inProgress = false;
    }
  }


}

export class UploadObject {
  directoryId: string;
  file: globalThis.File;
  progress = 0;
  progressInt = 0;
  started = false;

  constructor(directoryId: string, file: globalThis.File) {
    this.directoryId = directoryId;
    this.file = file;
  }
}
