import { NgModule } from '@angular/core';
import { TypePipe } from './type.pipe';

@NgModule({
    imports: [],
    declarations: [TypePipe],
    exports: [TypePipe]
})

export class TypePipeModule { }
