import { PageRequest } from 'src/app/domain/base/page-request';

export class RetrieveAddressListWithKeywordPageRequest extends PageRequest {
  keyword: string;

  constructor(phase: number, size: number, keyword: string) {
    super(phase, size);
    this.keyword = keyword;
  }
}
