import { Application } from '../../yon/auth/application';

export class Role {
    id: string;
    authority: string;
    appSlug: string;
    // application: Application;

    constructor() {
        // this.application = new Application();
    }
}
