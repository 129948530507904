/* eslint-disable max-len */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Account } from 'src/app/domain/auth/account/account';
import { PageRequest } from 'src/app/domain/base/page-request';
import { Response } from 'src/app/domain/base/response';
import { Address } from 'src/app/domain/commerce/address/address';
import { AddressProvider } from 'src/app/providers/commerce/address/address.provider';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../../interface/toast/toast.service';


declare var require: any;
const SEARCH_RESULTS_SIZE = 8;

@Injectable({ providedIn: 'root' })
export class AddressService {

  //#region Attributes & Properties

  private _addresss: Address[] = [];
  private _addressId: string;
  private _address: Address = new Address();

  private _pageRequest: PageRequest = new PageRequest(0, 20);
  private _searchResults: Address[] = [];
  private _keyword: string;

  public set addressId(value: string) {
    this._addressId = value;
    if (value !== this.address.id) {
      this.address = new Address();
    }
    if (value !== '_new') {
      this.getById();
    }
    else {
      this.address.id = this._addressId;
    }
  }
  public get addressId(): string { return this._addressId; }

  public get address(): Address { return this._address; }
  public set address(value: Address) { this._address = value; }

  public get addresss(): Address[] { return this._addresss; }
  public set addresss(value: Address[]) { this._addresss = value; }

  public get pageRequest(): PageRequest { return this._pageRequest; }
  public set pageRequest(value: PageRequest) { this._pageRequest = value; }

  public get searchResults(): Address[] { return this._searchResults; }
  public set searchResults(value: Address[]) { this._searchResults = value; }

  public get keyword(): string { return this._keyword; }
  public set keyword(value: string) { this._keyword = value; }

  //#endregion

  constructor(private addressProvider: AddressProvider, private authService: AuthService, private toastService: ToastService, private alertController: AlertController) {

    // if (this.authService.getRole() === 'admin') {
    //   this.list();
    // }
    // else if (this.authService.getRole() === 'customer') {
    //   this.listAccordingToOwner();
    // }

  }

  /**
   * creates address via
   * address provider
   */
  create() {
    const ObjectID = require('bson-objectid');
    this.address.id = ObjectID().toString();
    this.addressProvider.create(this.address).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.updateChangedObjectInList();
          this.toastService.present('Succcesful', 'Address successfully created.', 'success', 'bottom');
        }
        else {
          this.toastService.present('Problem Occured', 'Address could not be created!', 'danger', 'bottom');
        }
      }
    );
  }

  /**
   * creates address via
   * address provider
   */
  update() {
    this.addressProvider.update(this.address).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.updateChangedObjectInList();
          this.toastService.present('Succcesful', 'Address successfully updated.', 'success', 'bottom');
        }
        else {
          this.toastService.present('Problem Occured', 'Address could not be updated!', 'danger', 'bottom');
        }
      }
    );
  }

  /**
   * deletes address commerce via
   * address commerce provider
   */
  async delete(id: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Remove ' + this.addresss.find(p => p.id === id).name,
      message: 'Would you like to <strong>delete  \"' + this.addresss.find(p => p.id === id).name + '\"</strong>?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button'
        }, {
          text: 'Delete',
          cssClass: 'danger',
          id: 'confirm-button',
          handler: () => {
            this.addressProvider.delete(id).subscribe(
              (response: Response) => {
                if (response.status && response.data) {
                  this.addresss.splice(this.addresss.findIndex(t => t.id === id), 1);
                  this.toastService.present('Succcesful', 'Address successfully deleted.', 'success', 'bottom');
                }
                else {
                  this.toastService.present('Problem Occured', 'Address could not be deleted!', 'danger', 'bottom');
                }
              }
            );
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * gets address by id via
   * address provider
   */
  getById() {
    this.addressProvider.getById(this.addressId).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.address = response.data;
          if (!this.address.owner) {
            this.address.owner = new Account();
          }
        }
      }
    );
  }

  /**
   * lists addresss via
   * address provider
   */
  list(event?) {
    this.pageRequest = new PageRequest(0, 20);
    this.addressProvider.list(this.pageRequest.phase, this.pageRequest.size).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.addresss = response.data;
        }
        if (event) {
          event.target.complete();
        }
      }
    );
  }

  /**
   * lists addresss via
   * address provider
   */
  doInfinite(event) {
    this.pageRequest.phase++;
    this.addressProvider.list(this.pageRequest.phase, this.pageRequest.size).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.addresss = this.addresss.concat(response.data);
          event.target.complete();

          if (response.data.length === 0 || response.data.length < this.pageRequest.size) {
            event.target.disabled = true;
          }
        }
      }
    );
  }

  /**
   * lists addresss via
   * address provider
   */
  listAccordingToOwner(event?) {
    this.addressProvider.listAccordingToOwner(this.pageRequest.phase, this.pageRequest.size).subscribe(
      (response: Response) => {
        if (response.status && response.data) {
          this.addresss = response.data;
        }
        if (event) {
          event.target.complete();
        }
      }
    );
  }

  /**
   * search addresss by keyword via
   * address provider
   */
  search() {
    if (this.keyword.length > 1) {
      this.addressProvider.search(0, SEARCH_RESULTS_SIZE, this.keyword).subscribe(
        (response: Response) => {
          console.log(response);
          if (response.status && response.data) {
            this.searchResults = response.data;
          }
        }
      );
    }
    else if (this.keyword.length === 0) {
      this.searchResults = [];
    }
  }

  /**
   * updates changed object in list
   */
  updateChangedObjectInList() {
    let changed = false;
    this.addresss.forEach((p, i) => {
      if (p.id === this.address.id) {
        changed = true;
        this.addresss[i] = this.address;
      }
    });

    if (!changed) {
      this.addresss.push(this.address);
    }
  }
}
