/* eslint-disable max-len */
import { Component } from '@angular/core';
import { UploadManager } from './managers/upload/upload.manager';
import { AuthService } from './services/auth/auth.service';
import { AddressService } from './services/commerce/address/address.service';
import { ConfigurationService } from './services/configuration/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isSplashOpen = true;
  constructor(public auth: AuthService, private config: ConfigurationService, public uploadManager: UploadManager, public addressService: AddressService) {
    this.auth.authenticated.subscribe(isAuthenticated => {
      if (isAuthenticated !== null) {
        console.log('app component, ', isAuthenticated);
        this.isSplashOpen = false;
      }
    });
  }
}
