import { Request } from './request';

export class PageRequest extends Request {
    phase: number;
    size: number;

    constructor(phase: number, size: number) {
        super();
        this.phase = phase;
        this.size = size;
    }
}
