import { Type } from '../../terms/type/type';
import { Coordinate } from './coordinate';

export class Hotspot {
    id: string;
    coordinate: Coordinate;
    type: Type;
    typeId: string;
    sectionId: string;
    sceneId: string;
    content: string;

    constructor() {
        this.coordinate = new Coordinate();
        this.type = new Type();
    }
}
