/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
import { GenericRequest } from '../../../dto/request/generic-request.dto';

import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpManager } from 'src/app/managers/http/http.manager';
// import { FileCompressDto } from '../dto/request/file-compress.dto';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { parameters } from 'src/app/parameters/parameters';
import { File } from 'src/app/domain/media/file/file';
import { DeleteFileRequest } from 'src/app/domain/media/file/request/delete-file-request';
import { RetrieveFileWithIdRequest } from 'src/app/domain/media/file/request/retrieve-file-with-id-request';
import { UpdateFileRequest } from 'src/app/domain/media/file/request/update-file-request';

const childPath = 'file/';

@Injectable({ providedIn: 'root' })
export class FileProvider {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {

  }

  /**
   * uploads given blob and creates
   * new file in directory
   * with given parentId
   *
   * @param blob
   * @param parentId
   * @returns
   */
  create(blob: Blob, parentId: string): Observable<HttpEvent<{}>> {
    let formData: FormData = new FormData();
    formData.append('mfile', blob);

    return this.http.request(new HttpRequest('POST', parameters.mediaServer + childPath + 'create/' + parentId, formData, { reportProgress: true, responseType: 'text' })).pipe(
      map((response) => response)
    );
  }

  /**
   * updates file
   *
   * @param file
   * @returns
   */
  update(file: File): Observable<any> {
    return this.http.post(parameters.mediaServer + childPath + 'update', new UpdateFileRequest(file)).pipe(
      map((response: Response) => response)
    );
  }

  /**
   * deletes file
   *
   * @param id
   * @returns
   */
  delete(id: string): Observable<any> {
    return this.http.post(parameters.mediaServer + childPath + 'delete', new DeleteFileRequest(id)).pipe(
      map((response: Response) => response)
    );
  }

  /**
   * downloads file by id
   *
   * @param id
   * @returns Blob File
   */
  getById(id: string): Observable<any> {
    return this.http.post<Blob>(parameters.mediaServer + childPath + 'get-file', new RetrieveFileWithIdRequest(id), { responseType: 'blob' as 'json' }).pipe(
      map((response) => response));
  }

  /**
   * @param  id
   * @returns Observable
   */
  getVideoUrl(id: string): Observable<SafeUrl> {
    return this.http
      .get(parameters.mediaServer + 'media/video/mp4/' + id, { responseType: 'blob' })
      .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
