import { Info } from './info';
import { Account } from '../../auth/account/account';
import { Statement } from './statement';
import { Type } from '../../terms/type/type';
export class Item {
  id: string;
  infos: Info[];
  type: Type;
  typeId: string;
  dataType: Type;
  dataTypeId: string;
  statements: Statement[];
  creator: Account;
  creatorId: string;
  editMode: boolean;

  constructor() {
    this.infos = new Array();
    const info = new Info();
    this.infos.push(info);
    this.type = new Type();
    this.dataType = new Type();
    this.statements = new Array();
    this.creator = new Account();
    this.editMode = false;
  }
}
