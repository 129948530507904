import { Request } from 'src/app/domain/base/request';
import { File } from '../file';

export class UpdateFileRequest extends Request {
  file: File;

  constructor(file: File) {
    super();
    this.file = file;
  }
}
