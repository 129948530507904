import { Request } from 'src/app/domain/base/request';
import { Address } from '../address';


export class UpdateAddressRequest extends Request {
    address: Address;

    constructor(address: Address) {
        super();
        this.address = address;
    }
}
