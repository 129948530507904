import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { Scene } from 'src/app/domain/experience/place/scene';
import { Section } from 'src/app/domain/experience/place/section';

@Pipe({
    name: 'sectionFilter'
})

@Injectable()
export class SectionFilterPipe implements PipeTransform {
    transform(value: Section[], index, args?): Scene[] {

        if (value.length > 0) {
            return value[index] !== undefined ? value[index].scenes : new Array();
        }
        else {
            return new Array();
        }
    }
}
