import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SuggestionsComponent } from './suggestions.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePipeModule } from 'src/app/pipes/secure/secure.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SecurePipeModule
    ],
    declarations: [SuggestionsComponent],
    exports: [SuggestionsComponent]
})

export class SuggestionsModule { }
