import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { Account } from 'src/app/domain/auth/account/account';
import { AccountProvider } from 'src/app/providers/yon/auth/account.provider';
import { Response } from 'src/app/domain/base/response';

const TOKEN_KEY = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public isAuthenticated = false;
  public token: string;
  public account: Account;
  session;

  constructor(private jwtHelper: JwtHelperService,
    private accountProvider: AccountProvider) {
    this.account = new Account();
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.accountProvider.getCurrentAccount().subscribe(
        (response: Response) => {
          if (response.data) {
            this.token = response.data;
            this.decodeToken();
            this.authenticated.next(true);
          }
          else {
            this.authenticated.next(false);
          }
        },
        (error) => { this.authenticated.next(false); }
      );
    } else {
      this.authenticated.next(false);
    }
  }

  decodeToken() {
    this.account = (this.jwtHelper.decodeToken(this.token)).userDetails;
  }

  async saveToken() {
    await Storage.set({ key: TOKEN_KEY, value: this.token });
  }

  getRole() {
    if (this.token !== null) {
      const role = (this.jwtHelper.decodeToken(this.token)).role[0].authority;
      if (role === 'ROLE_ADMIN') {
        return 'admin';
      }
      else if (role === 'ROLE_USER') {
        return 'user';
      }
      else if (role === 'ROLE_PREMIUM') {
        return 'premium';
      }
      else if (role === 'ROLE_CUSTOMER') {
        return 'customer';
      }
    }
    else {
      return null;
    }
  }

  async logout(): Promise<void> {
    this.isAuthenticated = false;
    this.authenticated.next(false);
    this.account = new Account();
    return Storage.remove({ key: TOKEN_KEY });
  }
}
