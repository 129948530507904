/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AccountProvider } from './providers/yon/auth/account.provider';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgProgressModule } from 'ngx-progressbar';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DomainInterceptor } from './interceptors/domain.interceptor';
import { AppSlugInterceptor } from './interceptors/app-slug.interceptor';
import { DeviceInterceptor } from './interceptors/device.interceptor';
import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { HotspotsModalModule } from './modals/hotspots/hotspots.module';
import { InfopanelModalModule } from './modals/infopanel/infopanel.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      backButtonText: 'Back'
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    NgProgressModule.withConfig({
      trickleSpeed: 200,
      min: 20,
      meteor: false,
      spinner: false,
      color: '#d53815'
    }),
    HttpClientModule,
    HotspotsModalModule,
    InfopanelModalModule
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: DomainInterceptor,
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppSlugInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LocaleInterceptor,
    //   multi: true
    // },
    JwtHelperService,
    AccountProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
export function tokenGetter() {
  return localStorage.getItem('Authorization');
}
