export class Coordinate {
    xp: number;
    yp: number;
    zp: number;
    xr: number;
    yr: number;
    zr: number;

    constructor() {

    }
}