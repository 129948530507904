export class Type {
  id: string;
  key: string;
  name: string;
  clss: string;
  type: Type;
  relevants: Type[];

  constructor() {
    this.relevants = new Array();
  }
}
