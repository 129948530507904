import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecureService } from 'src/app/services/secure/secure.service';

@Pipe({
  name: 'secure',
  pure: true
})
export class SecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private secureService: SecureService) { }

  transform(url: string): Observable<SafeUrl> {
    if (url.split('/').pop() !== 'undefined' && url.split('/').pop() !== 'null') {
      if (this.secureService.urlMap.has(url)) {
        return from(new Promise(resolve => {
          resolve(this.secureService.urlMap.get(url));
        }));
      }
      else {
        return this.http
          .get(url, { responseType: 'blob' })
          .pipe(map(
            (val) => {
              const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val));
              this.secureService.urlMap.set(url, safeUrl);
              return safeUrl;
            }
          ));
      }
    }
  }
}
