import { PageRequestDto } from './page-request.dto';
export class SearchDto {
    pageRequestDto: PageRequestDto;
    data: any;
    id: string;

    constructor(pageRequstDto, data) {
        this.pageRequestDto = pageRequstDto;
        this.data = data;
    }
}