/* eslint-disable max-len */
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { parameters } from 'src/app/parameters/parameters';
import { map } from 'rxjs/operators';
import { Type } from 'src/app/domain/terms/type/type';
import { CreateTypeRequest } from 'src/app/domain/terms/type/request/create-type-request';
import { UpdateTypeRequest } from 'src/app/domain/terms/type/request/update-type-request';
import { DeleteTypeRequest } from 'src/app/domain/terms/type/request/delete-type-request';
import { RetrieveTypeListPageRequest } from 'src/app/domain/terms/type/request/retrieve-type-list-page-request';
import { RetrieveTypeWithIdRequest } from 'src/app/domain/terms/type/request/retrieve-type-with-id-request';
import { RetrieveTypeListWithKeywordPageRequest } from 'src/app/domain/terms/type/request/retrieve-type-list-with-keyword-page-request';
import { RetrieveTypeListWithClassRequest } from 'src/app/domain/terms/type/request/retrieve-type-list-with-class-request';
import { RetrieveTypeListWithClassAndTypeRequest } from 'src/app/domain/terms/type/request/retrieve-type-list-with-class-and-type-request';

const REQUEST_MAPPING = 'type/';

@Injectable({ providedIn: 'root' })
export class TypeProvider {

  constructor(private http: HttpClient) {
  }

  /**
   * creates a type
   *
   * @param type
   * @returns
   */
  create(type: Type): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'create', new CreateTypeRequest(type)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * updates a type.
   *
   * @param type
   * @returns
   */
  update(type: Type): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'update', new UpdateTypeRequest(type)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * deletes a type.
   *
   * @param id
   * @returns
   */
  delete(id: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'delete', new DeleteTypeRequest(id)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * lists the types.
   *
   * @param phase
   * @param size
   * @returns
   */
  list(phase: number, size: number): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list', new RetrieveTypeListPageRequest(phase, size)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * lists types by clss.
   *
   * @param clss
   * @returns
   */
  listByClass(clss: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list-by-class', new RetrieveTypeListWithClassRequest(clss)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * lists types by clss and type.
   *
   * @param clss
   * @returns
   */
  listByClassAndType(clss: string, type: Type): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list-by-class-and-type', new RetrieveTypeListWithClassAndTypeRequest(clss, type)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * retrieves types with id.
   *
   * @param id
   * @returns
   */
  getById(id: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'get-by-id', new RetrieveTypeWithIdRequest(id)).pipe(
      map(
        (response: Response) => response
      )
    );
  }


  /**
   * retrieves type with keyword.
   *
   * @param phase
   * @param size
   * @param keyword
   * @returns
   */
  search(phase: number, size: number, keyword: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'search', new RetrieveTypeListWithKeywordPageRequest(phase, size, keyword)).pipe(
      map(
        (response: Response) => response
      )
    );
  }
}
