import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HotspotsModal } from './hotspots.modal';

const routes: Routes = [
  {
    path: '',
    component: HotspotsModal
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HotspotsPageRoutingModule {}
