import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SecureService {
    urlMap: Map<string, SafeResourceUrl>;

    constructor() {
        this.urlMap = new Map();
    }
}
