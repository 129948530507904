import { Type } from '../../terms/type/type';
import { Preferences } from '../../yon/auth/preferences';
import { Department } from '../../yon/auth/department';
import { Role } from '../role/role';
import { Seat } from '../../yon/auth/seat';
import { Base } from '../../base/base';
export class Account extends Base {
  //id: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  photo: string;
  address: string;
  username: string;
  password: string;
  title: string;
  iban: string;
  preferences: Preferences;
  role: Role;
  roles: Role[];
  seats: Seat[];
  mainSeat: number;
  // department: Department;
  // type: Type;
  permanentIncome: number;
  activeIncome: number;
  accountNonExpired = true;
  credentialsNonExpired = true;
  accountNonLocked = true;
  enabled = true;

  constructor() {
    super();
    this.role = new Role();
    // this.department = new Department();
    // this.type = new Type();
    this.preferences = new Preferences();
    this.seats = new Array();
  }
}
