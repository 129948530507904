
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpManager } from 'src/app/managers/http/http.manager';
import { LoginDto } from 'src/app/dto/request/login.dto';
import { parameters } from 'src/app/parameters/parameters';
import { Account } from 'src/app/domain/auth/account/account';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';
import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { AccountRoleDto } from 'src/app/dto/request/account-role.dto';
import { PasswordDto } from 'src/app/dto/request/password.dto';
import { Preferences } from 'src/app/domain/yon/auth/preferences';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CreateAccountWithDomainIdRequest } from 'src/app/domain/yon/auth/request/create-account-with-domain-id-request';
import { Response } from 'src/app/domain/base/response';

import { ValidateEmailRequest } from 'src/app/domain/auth/account/request/validate-email-request';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Storage } from '@capacitor/storage';


const childPath = 'account/';

@Injectable()
export class AccountProvider {

  constructor(private httpManager: HttpManager,
    // private authService: AuthService,
    private jwtHelper: JwtHelperService) {
  }


  login(loginDto: LoginDto): Observable<any> {
    return this.httpManager.post(parameters.authServer + 'login', loginDto).pipe(
      map((response: Response) => response)
    );
  }

  register(account: Account): Observable<any> {
    return this.httpManager.post(parameters.authServer + 'register', new CreateAccountWithDomainIdRequest(account)).pipe(
      map((response: Response) => response)
    );
  }

  getCurrentAccount(): Observable<any> {
    return this.httpManager.postWithoutData(parameters.authServer + childPath + 'get-current-account').pipe(
      map((response: Response) => response)
    );
  }

  create(account: Account): Observable<any> {
    return this.httpManager.post(parameters.authServer + childPath + 'create', account).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  update(account: Account): Observable<any> {
    return this.httpManager.post(parameters.authServer + childPath + 'update', account).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  delete(genericReq: GenericRequest): Observable<any> { //Add Account Id to Generic Request's data attribute
    return this.httpManager.post(parameters.authServer + childPath + 'delete', genericReq).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  search(pageRequest: PageRequestDto) {
    return this.httpManager.post(parameters.authServer + childPath + 'search', pageRequest).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  getById(genericReq: GenericRequest): Observable<any> {//Add Account Id to Generic Request's data attribute
    return this.httpManager.post(parameters.authServer + childPath + 'get-by-id', genericReq).pipe(
      map(
        (res) => {
          if (res != null) {
            const account: Account = res;
            return account;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  getByUsername(genericReq: GenericRequest): Observable<any> {//Add Account Id to Generic Request's data attribute
    return this.httpManager.post(parameters.authServer + childPath + 'get-by-username', genericReq).pipe(
      map(
        (res) => {
          if (res != null) {
            const account: Account = res;
            return account;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  addRole(accountRoleDto: AccountRoleDto): Observable<any> { //Add Role Id's to AccountRoleDto roles string list
    return this.httpManager.post(parameters.authServer + childPath + 'add-role', accountRoleDto).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  deleteRole(accountRoleDto: AccountRoleDto): Observable<any> {//Add Role Id's to AccountRoleDto roles string list
    return this.httpManager.post(parameters.authServer + childPath + 'delete-role', accountRoleDto).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  /**
   * Changes password by getting old password
   *
   * @param passwordDto
   * @returns boolean
   */

  changePassword(passwordDto: PasswordDto) {
    return this.httpManager.post(parameters.authServer + childPath + 'change-password', passwordDto).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  /**
   * Updates password by not requiring old password
   *
   * @param passwordDto
   * @returns boolean
   */

  updatePassword(passwordDto: PasswordDto) {
    return this.httpManager.post(parameters.authServer + childPath + 'update-password', passwordDto).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }

  updatePreferences(preferences: Preferences) {
    return this.httpManager.post(parameters.authServer + childPath + 'update-preferences', preferences).pipe(
      map(
        (status) => {
          if (status != null) {
            return status;
          }
          else {
            return null;
          }
        }
      )
    );
  }
  /**
   * Lists all Accounts
   *
   * @returns Array
   */

  list(genericReq: GenericRequest): Observable<any> {
    return this.httpManager.post(parameters.authServer + childPath + 'list', genericReq).pipe(
      map(
        (res) => {
          if (res != null) {
            const accounts: Account[] = res;
            return accounts;
          }
          else {
            return null;
          }
        }
      )
    );
  }



}
