import { Request } from 'src/app/domain/base/request';

export class DeleteAddressRequest extends Request {
  id: string;

  constructor(id: string) {
    super();
    this.id = id;
  }
}
