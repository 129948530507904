/* eslint-disable @typescript-eslint/dot-notation */
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginDto } from 'src/app/dto/request/login.dto';

import { Device } from '@capacitor/device';
import { Storage } from '@capacitor/storage';
import { AuthService } from '../auth/auth.service';
import { AccountProvider } from 'src/app/providers/yon/auth/account.provider';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  device;

  constructor(@Inject(DOCUMENT) private document: Document,
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private accountProvider: AccountProvider,
    private languageService: LanguageService) {
    this.getDeviceInfo();
    this.authService.loadToken();
    this.languageService.loadLanguage();
  }

  async getDeviceInfo() {
    this.device = await Device.getInfo();
    console.log(this.device);
  }

  async setLanguage() {
    const language = await Storage.get({ key: 'language' });

    if (language) {
      this.languageService.language = language.value;
    }
    else {
      const lang = await Device.getLanguageCode();
      const langKey = lang.value.split('-')[0];
      const presentLang = this.languageService.getLanguages().find(l => l['key'] === langKey)['key'];
      this.languageService.language = presentLang !== undefined ? presentLang : 'en';
    }

    this.document.documentElement.lang = this.languageService.language;
  }

  async getSession() {
    const token = await Storage.get({ key: 'Authorization' });
    if (token) {
      return this.jwtHelper.decodeToken(token.value).session;
    }
  }

  getCurrentAccount() {
    this.accountProvider.getCurrentAccount().subscribe(
      (res) => {
        if (res == null) {
        }
        else {
          Storage.set({ key: 'Authorization', value: res.data });
          this.authService.account = this.jwtHelper.decodeToken(res.data).userDetails;
          this.authService.session = this.jwtHelper.decodeToken(res.data).session;
        }
      },
      (err) => {
      }
    );
  }

}
