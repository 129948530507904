/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Response } from 'src/app/domain/base/response';
import { Address } from 'src/app/domain/commerce/address/address';
import { CreateAddressRequest } from 'src/app/domain/commerce/address/request/create-address-request';
import { DeleteAddressRequest } from 'src/app/domain/commerce/address/request/delete-address-request';
import { RetrieveAddressListPageRequest } from 'src/app/domain/commerce/address/request/retireve-address-list-page-request';
import { RetrieveAddressWithIdRequest } from 'src/app/domain/commerce/address/request/retrieve-address-with-id-request';
import { RetrieveAddressListWithKeywordPageRequest } from 'src/app/domain/commerce/address/request/retrieve-address-list-with-keyword-page-request';
import { UpdateAddressRequest } from 'src/app/domain/commerce/address/request/update-address-request';
import { RetrieveAddressListWithOwnerIdPageRequest } from 'src/app/domain/commerce/address/request/retrieve-address-list-with-owner-id-page-request';
import { parameters } from 'src/app/parameters/parameters';
import { HttpClient } from '@angular/common/http';


const REQUEST_MAPPING = 'commerce/address/';

@Injectable({ providedIn: 'root' })
export class AddressProvider {

  constructor(private http: HttpClient) {
  }

  /**
   * creates a address
   *
   * @param address
   * @returns
   */
  create(address: Address): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'create', new CreateAddressRequest(address)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * updates a address.
   *
   * @param address
   * @returns
   */
  update(address: Address): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'update', new UpdateAddressRequest(address)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * deletes a address.
   *
   * @param id
   * @returns
   */
  delete(id: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'delete', new DeleteAddressRequest(id)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * lists the addresss.
   *
   * @param phase
   * @param size
   * @returns
   */
  list(phase: number, size: number): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list', new RetrieveAddressListPageRequest(phase, size)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * lists the addresss according to owner.
   *
   * @param phase
   * @param size
   * @returns
   */
  listAccordingToOwner(phase: number, size: number): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list-according-to-owner', new RetrieveAddressListPageRequest(phase, size)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * retrieves addresss with id.
   *
   * @param id
   * @returns
   */
  getById(id: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'get-by-id', new RetrieveAddressWithIdRequest(id)).pipe(
      map(
        (response: Response) => response
      )
    );
  }


  /**
   * retrieves address list with owner id.
   *
   * @param phase
   * @param size
   * @param ownerId
   * @returns
   */
  listByOwnerId(phase: number, size: number, ownerId: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'list-by-owner-id', new RetrieveAddressListWithOwnerIdPageRequest(phase, size, ownerId)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

  /**
   * retrieves address with keyword.
   *
   * @param phase
   * @param size
   * @param keyword
   * @returns
   */
  search(phase: number, size: number, keyword: string): Observable<any> {
    return this.http.post(parameters.server + REQUEST_MAPPING + 'search', new RetrieveAddressListWithKeywordPageRequest(phase, size, keyword)).pipe(
      map(
        (response: Response) => response
      )
    );
  }

}
