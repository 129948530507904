import { NgModule } from '@angular/core';
import { SceneFilterPipe } from './scene-filter.pipe';

@NgModule({
    imports: [],
    declarations: [SceneFilterPipe],
    exports: [SceneFilterPipe]
})

export class SceneFilterPipeModule { }
