import { NgModule } from '@angular/core';
import { SectionFilterPipe } from './section-filter.pipe';

@NgModule({
    imports: [],
    declarations: [SectionFilterPipe],
    exports: [SectionFilterPipe]
})

export class SectionFilterPipeModule { }
