import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { from, Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
/** Simulate server replying to file upload request */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('login')) {
      if (this.authService.token) {
        return next.handle(
          req.clone({
            headers: req.headers.append('Authorization', this.authService.token)
          })
        );
      }
      else {
        return from(this.handleAccess(req, next));
      }
    }
    else {
      return next.handle(req.clone());
    }

  }

  private async handleAccess(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    return Storage.get({ key: 'Authorization' }).then(
      (token) => {
        if (token) {
          return next.handle(
            req.clone({
              headers: req.headers.append('Authorization', token.value)
            })
          ).toPromise();
        }
        else {
          return next.handle(req.clone()).toPromise();
        }
      }
    );
  }
}
