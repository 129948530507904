import { ContextmenuComponent } from './contextmenu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations: [ContextmenuComponent],
    exports: [ContextmenuComponent]
})

export class ContextmenuModule { }