import { PageRequest } from 'src/app/domain/base/page-request';

export class RetrieveAddressListWithOwnerIdPageRequest extends PageRequest {
  ownerId: string;

  constructor(phase: number, size: number, ownerId: string) {
    super(phase, size);
    this.ownerId = ownerId;
  }
}
