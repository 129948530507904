/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(private toastController: ToastController) { }

  async present(header?: string, message?: string, color?: string, position?: any) {
    const toast = await this.toastController.create({
      header: header,
      message: message,
      color: color,
      position: position,
      duration: 2000
    });
    await toast.present();
  }
}
