import { Account } from '../auth/account/account';

export class Base {
  id: string;
  createdDate: Date;
  lastModifiedDate: Date;
  version: number;
  creatorId: string;
  ownerId: string;
  domainIds: string[];
  active: boolean;
  draft: boolean;
  owner: Account;
}
