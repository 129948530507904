export class PageRequestDto {
    size: number;
    phase: number;
    data: any;

    constructor(size, phase, data) {
        this.size = size;
        this.phase = phase;
        this.data = data;
    }
}