/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable no-var */
/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Response } from 'src/app/domain/base/response';
import { Type } from 'src/app/domain/terms/type/type';
import { Info } from 'src/app/domain/yon/auth/info';
import { Item } from 'src/app/domain/yon/auth/item';
import { Statement } from 'src/app/domain/yon/auth/statement';

import { GenericRequest } from 'src/app/dto/request/generic-request.dto';
import { PageRequestDto } from 'src/app/dto/request/page-request.dto';
import { SearchDto } from 'src/app/dto/request/search.dto';
import { TypeProvider } from 'src/app/providers/terms/type/type.provider';
import { ItemProvider } from 'src/app/providers/yon/auth/item.provider';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-infopanel',
  templateUrl: './infopanel.modal.html',
  styleUrls: ['./infopanel.modal.scss'],
})
export class InfopanelModal implements OnInit {

  @Input('statements')
  public statements: Statement[] = new Array();

  types: Type[];
  results: Item[];

  changeClosed = false;
  pSearchIndex = 0;
  qSearchIndex = -1;
  pageRequest: PageRequestDto;
  searchDto: SearchDto;
  languages;

  constructor(private modalController: ModalController,
    private alertController: AlertController,
    public authService: AuthService,
    private typeService: TypeProvider,
    private itemService: ItemProvider) {
    this.types = new Array();
    this.results = new Array();
    this.pageRequest = new PageRequestDto(5, 0, null);
    this.searchDto = new SearchDto(this.pageRequest, null);
  }

  ngOnInit() {
    this.listTypes();
  }

  listTypes() {
    this.typeService.listByClass('Item').subscribe(
      (response: Response) => {
        if (response && response.status) {
          this.types = response.data;
          this.types = this.types.filter(t => t.relevants.filter(r => r.name === 'Ana Tür').length > 0);
        }
      },
      (err) => {

      }
    );
  }

  search(opt: number, pIndex: number, qIndex: number, $event) {

    if (!this.changeClosed) {
      this.pSearchIndex = pIndex;
      this.qSearchIndex = qIndex;

      this.pageRequest.data = $event.target.value;

      if (this.pageRequest.data.length > 1) {
        if (this.statements[pIndex].property.dataTypeId === '5eb05c391051e45b98d6900c' || opt === 1) { //if dataType key is "property-type"

          console.log('Property Index: ' + this.pSearchIndex + 'Value Index' + this.qSearchIndex);

          this.searchDto.pageRequestDto = this.pageRequest;
          this.searchDto.id = this.types[0].id;
          this.itemService.searchByType(this.searchDto).subscribe(
            (response: Response) => {
              if (response && response.status) {
                this.results = response.data;
              }
            },
            (err) => {

            });
        }
        else if (this.statements[pIndex].property.dataTypeId === '5eb05b971051e45b98d69005') { //if dataType key is "item"
          console.log('Property Index: ' + this.pSearchIndex + 'Value Index' + this.qSearchIndex);

          //this.pageRequest.data = this.qSearchTerm;
          this.searchDto.pageRequestDto = this.pageRequest;
          this.searchDto.id = this.types[1].id;
          this.itemService.searchByType(this.searchDto).subscribe(
            (response: Response) => {
              if(response && response.status){
                this.results = response.data;
              }
            },
            (err) => {

            }
          );
        }
      }
    } else {
      this.changeClosed = false;
    }

  }

  createNewStatement() {
    const statement: Statement = new Statement();
    const item: Item = new Item();
    statement.values.push(item);
    statement.creator = this.authService.account;
    statement.editMode = true;
    this.statements.push(statement);
  }

  createNewValue(pIndex: number) {
    if (this.statements[pIndex].property.dataTypeId === '5eb05c391051e45b98d6900c' || this.statements[pIndex].property.dataTypeId === '5eb05b971051e45b98d69005') { //if dataType key is "property-type" or //if dataType key is "item"
      const value: Item = new Item();
      this.statements[pIndex].values.push(value);
    }
  }

  async deleteStatement(pIndex: number) {
    //this.item.statements[pIndex]
    const alert = await this.alertController.create({
      header: 'Sil!',
      message: this.statements[pIndex].property.infos[0].name + ' öğesini <strong>silmek</strong> istiyor musunuz?',
      buttons: [
        {
          text: 'Vazgeç',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Sil',
          handler: () => {
            this.statements.splice(pIndex, 1);
            //this.anyChange = true;
          }
        }
      ]
    });

    await alert.present();
  }

  selectItem(selected, sIndex, qIndex) {
    if (qIndex === -1) {
      this.statements[sIndex].property = selected;
    }
    else {
      this.statements[sIndex].values[qIndex] = selected;
    }
    this.results = new Array();
    this.changeClosed = true;
  }

  propertyInputFocus(pIndex: number) {
    // if (this.item.statements[pIndex].property.infos[0].name !== null) {//If property search then new property object
    //   console.log(this.item.statements[pIndex].property)
    //   this.item.statements[pIndex].property = new Item();
    //   //this.item.statements[pIndex].property.infos
    //   this.item.statements[pIndex].values = new Array();

    //   let value: Item = new Item();

    //   this.item.statements[pIndex].values.push(value);
    // }
  }

  valueInputBlur(e, pIndex: number, qIndex: number) {
    if (this.statements[pIndex].property.dataTypeId === '5eb05c2e1051e45b98d6900b') { //if dataType key is "image"
      //this.anyChange = true;
    }
    else if (this.statements[pIndex].property.dataTypeId === '5eb05b7c1051e45b98d69004') { //if dataType key is "string"
      console.log(e.target.value);

      const info = new Info();
      info.name = e.target.value;
      //todo language controller will be here
      info.language = this.authService.account.preferences.language;
      this.statements[pIndex].values[qIndex].dataType = null;
      this.statements[pIndex].values[qIndex].type = this.statements[pIndex].property.dataType;
      this.statements[pIndex].values[qIndex].infos[0].name = e.target.value;
      this.statements[pIndex].values[qIndex].creatorId = this.authService.account.id;
    }
  }

  approve() {
    this.modalController.dismiss(this.statements);
  }
}
