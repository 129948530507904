import { Account } from '../../auth/account/account';
import { Item } from './item';
export class Statement {
    property: Item;
    values: Item[];
    creator: Account;
    editMode: boolean;

    constructor() {
        this.property = new Item();
        this.values = new Array();
        this.creator = new Account();
        this.editMode = false;
    }
}
