/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent implements OnInit {

  @Output()
  public clicked = new EventEmitter();

  @Input('items')
  public items: any[];

  @Input('config')
  public config: SuggestionsConfig = {
    imageKey: '',
    titleKeys: [''],
    subtitleKey: '',
    showImage: true,
    showTitle: true,
    showSubtitle: true
  };

  constructor() {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    // let key: SimpleChange = changes.key;
    // this._key = key.currentValue

    // if (this._key != "") {
    //   // this.pageRequest.data = this._key;
    //   // this.search();
    // }
    // else {
    //   this.subjects = new Array();
    // }
  }

  returnValue(selection: any) {
    this.clicked.emit(selection);
  }
}

export class SuggestionsConfig {
  imageKey?;
  titleKeys?;
  subtitleKey?;
  showImage?= true;
  showTitle?= true;
  showSubtitle?= true;
}
