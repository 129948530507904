import { Request } from 'src/app/domain/base/request';
import { Type } from '../type';

export class RetrieveTypeListWithClassAndTypeRequest extends Request {
  clss: string;
  type: Type;

  constructor(clss: string, type: Type) {
    super();
    this.clss = clss;
    this.type = type;
  }
}
