/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-output-native */
import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
})
export class ContextmenuComponent implements OnInit {

  @Input() options: ContextmenuConfig = {
    items: [],
    x: 0,
    y: 0,
    height: '0'
  };
  @Output() select = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  itemClicked(text) {
    this.select.emit(text);
  }

}

export class ContextmenuConfig {
  items?;
  x?;
  y?;
  height?;
}
