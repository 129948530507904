import { Request } from 'src/app/domain/base/request';

export class RetrieveTypeListWithClassRequest extends Request {
  clss: string;

  constructor(clss: string) {
    super();
    this.clss = clss;
  }
}
