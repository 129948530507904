/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable no-var */
/* eslint-disable max-len */
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, OnInit, ViewChild, ElementRef, Inject, NgZone, Renderer2, ComponentFactoryResolver, ApplicationRef, Injector, HostListener, Input, SecurityContext } from '@angular/core';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ModalController, AlertController } from '@ionic/angular';
import { Scene } from '../../domain/experience/place/scene';
import { Section } from '../../domain/experience/place/section';
import { Hotspot } from '../../domain/experience/place/hotspot';
import { SecurePipe } from 'src/app/pipes/secure/secure.pipe';
import { VideoTexture } from 'three';
import { Type } from 'src/app/domain/terms/type/type';
import { FileProvider } from 'src/app/providers/media/file/file.provider';
import { TypeProvider } from 'src/app/providers/terms/type/type.provider';
import * as THREE from 'three';
import { Response } from 'src/app/domain/base/response';


declare var require: any;

const MEDIA_URL = 'https://api.yon.tc/visetra-api-v2/media/image/post/';

@Component({
  selector: 'app-hotspots',
  templateUrl: './hotspots.modal.html',
  styleUrls: ['./hotspots.modal.scss'],
})

export class HotspotsModal implements OnInit {

  @Input('returnAll')
  public returnAll = false;

  @Input('scenes')
  public scenes: Scene[];

  @Input('index')
  public index: number;

  @Input('sections')
  public sections: Section[];

  @Input('type')
  public type: Type;

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('cssRendererContainer', { static: true })
  public cssRendererContainer: ElementRef<HTMLDivElement>;

  @ViewChild('video', { static: true })
  public video: ElementRef<HTMLVideoElement>;

  private isUserInteracting = false;

  private onMouseDownMouseX = 0;
  private onMouseDownMouseY = 0;
  private lon = 0;
  private onMouseDownLon = 0;
  private lat = 0;
  private onMouseDownLat = 0;
  private phi = 0;
  private theta = 0;

  private canvas: HTMLCanvasElement;
  private controls: OrbitControls;
  private renderer: THREE.WebGLRenderer;
  private css3DRenderer: CSS3DRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private light: THREE.AmbientLight;
  private material: THREE.MeshBasicMaterial;
  private cube: THREE.Mesh;
  private mousePosition: THREE.Vector2;
  private rayCaster: THREE.Raycaster;

  private frameId: number = null;
  private texture = '';

  public isContextmenuOpen = false;
  public mouseDownX: number;
  public mouseDownY: number;

  public types: Type[] = [];
  public coreTypes: Type[] = [];
  public infospotTypes: Type[] = [];

  public selectedScene = '';
  public selectedTypeName = '';

  constructor(private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private securePipe: SecurePipe,
    private modalController: ModalController,
    private alertController: AlertController,
    private fileProvider: FileProvider,
    private typeProvider: TypeProvider) {
  }

  ngOnInit() {
    //this.getImage();
    this.getContent();
    this.listTypes();
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    if (this.frameId !== null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  changeScene(i) {
    this.displaceHotspots();
    this.index = i;
    // this.scene = this.scenes[this.index];
    this.getContent();
  }

  displaceHotspots() {
    this.scenes[this.index].hotspots.forEach(hotspot => {
      const object = this.scene.getObjectByName(hotspot.id) as CSS3DObject;
      this.scene.remove(object);
    });
  }

  placeHotspots() {
    console.log('Hotspots: ', this.scenes[this.index].hotspots);

    this.scenes[this.index].hotspots.forEach(hotspot => {
      hotspot['expanded'] = false;
      const element = document.createElement('div');
      element.className = 'hotspot-point clickable ' + this.getHotspotClassName(hotspot.typeId);

      if (hotspot.typeId === '5fae8c9c699373648481be87') { //if hotspot type is scene-link

        const image = document.createElement('div');
        image.className = 'hotspot-point__img';

        const sceneName = document.createElement('span');
        const linkedScene = this.scenes.find(s => s.id === hotspot.sceneId);
        const id = linkedScene.coverId;

        sceneName.classList.add('scene-link__name');
        sceneName.innerText = linkedScene.name;

        element.appendChild(sceneName);

        this.securePipe.transform('https://api.yon.tc/visetra-api-v2/media/image/thumb/' + linkedScene.coverId).subscribe(
          (safeUrl: SafeUrl) => {
            //console.log("SafeUrl: " + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl))
            image.style.background = 'url(' + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl) + ')';

          }
        );
        element.appendChild(image);

        element.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '5ff58bb8699373648481bea2') { //if hotspot type is section-link
        console.log('Sections: ', this.sections);
        const text = this.sections.filter(s => s.id === hotspot.sectionId)[0].name;
        //let link = hotspot.content.split(';')[1];

        //Button for front element
        const button = document.createElement('ion-button');
        button.id = 'bt-' + hotspot.id;
        button.innerText = text;
        button.size = 'default';
        button.fill = 'outline';
        button.color = 'white';
        //Append front button to hotspot div
        element.appendChild(button);

        //Open outside icon
        const openIcon = document.createElement('ion-icon');
        openIcon.name = 'arrow-forward-outline';
        openIcon.slot = 'end';

        //Append open outside icon to button to show user it will open in outside of the site
        button.appendChild(openIcon);


        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        button.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '604237fb699373648481bed0') { //if hotspot type is sections-menu

        //Button for front element
        const button = document.createElement('ion-button');
        button.id = 'bt-' + hotspot.id;
        button.innerText = 'Bölümler';
        button.size = 'default';
        button.fill = 'outline';
        button.color = 'white';
        //Append front button to hotspot div
        element.appendChild(button);

        //Open outside icon
        const openIcon = document.createElement('ion-icon');
        openIcon.name = 'menu-outline';
        openIcon.slot = 'icon-only';

        //Append open outside icon to button to show user it will open in outside of the site
        button.appendChild(openIcon);


        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        button.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '60423833699373648481bed1') { //if hotspot type is info-panel

        //Button for front element
        const button = document.createElement('ion-button');
        button.id = 'bt-' + hotspot.id;
        //button.innerText = "Panel";
        button.size = 'large';
        button.fill = 'clear';
        button.color = 'white';
        //Append front button to hotspot div
        element.appendChild(button);

        //Open outside icon
        const openIcon = document.createElement('ion-icon');
        //openIcon.name = "information-outline";
        openIcon.src = 'assets/icons/info-filled.svg';
        openIcon.slot = 'icon-only';

        //Append open outside icon to button to show user it will open in outside of the site
        button.appendChild(openIcon);

        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        button.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '5fb3ec02699373648481be89') { //if hotspot type is video-infospot
        //Icon for front element
        const icon = document.createElement('ion-icon');
        icon.name = this.getIconName(hotspot.typeId);
        icon.id = 'ic-' + hotspot.id;
        icon.className = 'hotspot__icon';

        //Append front icon to hotspot div
        element.appendChild(icon);

        //iframe container to contain iframe and close button
        const iframeCont = document.createElement('div');
        iframeCont.id = hotspot.id;
        iframeCont.className = 'hotspot__media-container';

        //Append iframe container to hotspot div
        element.appendChild(iframeCont);

        //Button to contain close icon and to close
        const button = document.createElement('ion-button');
        button.className = 'hotspot__media-close';
        button.fill = 'clear';

        //Close icon
        const closeIcon = document.createElement('ion-icon');
        closeIcon.name = 'close-outline';
        closeIcon.slot = 'icon-only';

        //Set onclick event of close button to mediaClose function
        button.onclick = ($event) => {
          this.mediaClose($event, hotspot);
        };

        //Append close icon to close button
        button.appendChild(closeIcon);
        //Append close button to iframe container
        iframeCont.appendChild(button);

        //iframe to show video
        const iframe = document.createElement('iframe');
        iframe.className = 'hotspot__video';
        iframe.src = hotspot.content;
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('frameborder', '0');

        //Append iframe to iframe container
        iframeCont.appendChild(iframe);

        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        icon.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '5fb3ec53699373648481be8b') { //if hotspot type is photo-infospot
        //Icon for front element
        const icon = document.createElement('ion-icon');
        icon.name = this.getIconName(hotspot.typeId);
        icon.id = 'ic-' + hotspot.id;
        icon.className = 'hotspot__icon';

        //Append front icon to hotspot div
        element.appendChild(icon);

        //iframe container to contain iframe and close button
        const imgCont = document.createElement('div');
        imgCont.id = hotspot.id;
        imgCont.className = 'hotspot__media-container';

        //Append img container to hotspot div
        element.appendChild(imgCont);

        //Button to contain close icon and to close
        const button = document.createElement('ion-button');
        button.className = 'hotspot__media-close';
        button.fill = 'clear';

        //Close icon
        const closeIcon = document.createElement('ion-icon');
        closeIcon.name = 'close-outline';
        closeIcon.slot = 'icon-only';

        //Set onclick event of close button to mediaClose function
        button.onclick = ($event) => {
          this.mediaClose($event, hotspot);
        };

        //Append close icon to close button
        button.appendChild(closeIcon);
        //Append close button to iframe container
        imgCont.appendChild(button);

        //img to show image
        const img = document.createElement('img');
        img.src = hotspot.content;
        img.className = 'hotspot__photo';

        //Append img to img container
        imgCont.appendChild(img);

        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        icon.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else if (hotspot.typeId === '5fb3ec33699373648481be8a') { //if hotspot type is link-infospot

        const text = hotspot.content.split(';')[0];
        const link = hotspot.content.split(';')[1];

        //Button for front element
        const button = document.createElement('ion-button');
        button.id = 'bt-' + hotspot.id;
        button.innerText = text;
        button.href = link;
        button.target = '_blank';
        button.size = 'small';

        //Append front button to hotspot div
        element.appendChild(button);

        //Open outside icon
        const openIcon = document.createElement('ion-icon');
        openIcon.name = 'open-outline';
        openIcon.slot = 'start';

        //Append open outside icon to button to show user it will open in outside of the site
        button.appendChild(openIcon);


        //Set onclick event of hotspot fron icon to hotspotClick function
        //hotspotClick function is to do things when hotspot clicked
        button.onclick = ($event) => {
          this.hotspotClick($event, hotspot);
        };
      }
      else {
        const icon = document.createElement('ion-icon');
        icon.name = this.getIconName(hotspot.typeId);
        icon.className = 'hotspot__icon';

        element.appendChild(icon);
      }

      if (hotspot.typeId === '5fb3ebe8699373648481be88') { //if placeExperience hotspot is written-infospot
        const explanation = document.createElement('p');
        explanation.innerHTML = hotspot.content;
        explanation.className = 'explanation';

        element.appendChild(explanation);
      }


      element.onclick = ($event) => {
        this.hotspotClick($event, hotspot.id);
      };

      var object = new CSS3DObject(element);
      object.name = hotspot.id;
      object.position.x = hotspot.coordinate.xp;
      object.position.y = hotspot.coordinate.yp;
      object.position.z = hotspot.coordinate.zp;

      object.rotation.x = hotspot.coordinate.xr;
      object.rotation.y = hotspot.coordinate.yr;
      object.rotation.z = hotspot.coordinate.zr;
      this.scene.add(object);
    });
  }

  createHotspot() {

    const ObjectID = require('bson-objectid');

    const hotspot = new Hotspot();
    hotspot.id = ObjectID().toString();

    this.mousePosition.x = (this.mouseDownX / this.renderer.domElement.clientWidth) * 2 - 1;
    this.mousePosition.y = -(this.mouseDownY / this.renderer.domElement.clientHeight) * 2 + 1;

    this.cube.updateMatrixWorld();
    this.rayCaster.setFromCamera(this.mousePosition, this.camera);

    var intersects = this.rayCaster.intersectObject(this.scene.getObjectByName('prism_Obj'), true);

    var element = document.createElement('div');
    element.className = 'hotspot-point clickable ' + this.getHotspotClassName(hotspot.typeId);

    if (hotspot.typeId === '5fae8c9c699373648481be87') { //if hotspot type is scene-link

      const image = document.createElement('div');
      image.className = 'hotspot-point__img';
      const id = this.scenes.find(s => s.id === hotspot.sceneId).coverId;

      this.securePipe.transform('https://api.yon.tc/visetra-api-v2/media/image/thumb/' + id).subscribe(
        (safeUrl: SafeUrl) => {
          //console.log("SafeUrl: " + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl))
          image.style.background = 'url(' + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl) + ')';

        }
      );

      element.appendChild(image);

      element.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5ff58bb8699373648481bea2') { //if hotspot type is section-link

    }
    else if (hotspot.typeId === '5fb3ec02699373648481be89') { //if hotspot type is video-infospot
      //Icon for front element
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.id = 'ic-' + hotspot.id;
      icon.className = 'hotspot__icon';

      //Append front icon to hotspot div
      element.appendChild(icon);

      //iframe container to contain iframe and close button
      const iframeCont = document.createElement('div');
      iframeCont.id = hotspot.id;
      iframeCont.className = 'hotspot__media-container';

      //Append iframe container to hotspot div
      element.appendChild(iframeCont);

      //Button to contain close icon and to close
      const button = document.createElement('ion-button');
      button.className = 'hotspot__media-close';
      button.fill = 'clear';

      //Close icon
      const closeIcon = document.createElement('ion-icon');
      closeIcon.name = 'close-outline';
      closeIcon.slot = 'icon-only';

      //Set onclick event of close button to mediaClose function
      button.onclick = ($event) => {
        this.mediaClose($event, hotspot);
      };

      //Append close icon to close button
      button.appendChild(closeIcon);
      //Append close button to iframe container
      iframeCont.appendChild(button);

      //iframe to show video
      const iframe = document.createElement('iframe');
      iframe.className = 'hotspot__video';
      iframe.src = hotspot.content;
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('frameborder', '0');

      //Append iframe to iframe container
      iframeCont.appendChild(iframe);

      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      icon.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5fb3ec53699373648481be8b') { //if hotspot type is photo-infospot
      //Icon for front element
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.id = 'ic-' + hotspot.id;
      icon.className = 'hotspot__icon';

      //Append front icon to hotspot div
      element.appendChild(icon);

      //iframe container to contain iframe and close button
      const imgCont = document.createElement('div');
      imgCont.id = hotspot.id;
      imgCont.className = 'hotspot__media-container';

      //Append img container to hotspot div
      element.appendChild(imgCont);

      //Button to contain close icon and to close
      const button = document.createElement('ion-button');
      button.className = 'hotspot__media-close';
      button.fill = 'clear';

      //Close icon
      const closeIcon = document.createElement('ion-icon');
      closeIcon.name = 'close-outline';
      closeIcon.slot = 'icon-only';

      //Set onclick event of close button to mediaClose function
      button.onclick = ($event) => {
        this.mediaClose($event, hotspot);
      };

      //Append close icon to close button
      button.appendChild(closeIcon);
      //Append close button to iframe container
      imgCont.appendChild(button);

      //img to show image
      const img = document.createElement('img');
      img.src = hotspot.content;
      img.className = 'hotspot__photo';

      //Append img to img container
      imgCont.appendChild(img);

      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      icon.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5fb3ec33699373648481be8a') { //if hotspot type is link-infospot

      const text = hotspot.content.split(';')[0];
      const link = hotspot.content.split(';')[1];

      //Button for front element
      const button = document.createElement('ion-button');
      button.id = 'bt-' + hotspot.id;
      button.innerText = text;
      button.href = link;
      button.target = '_blank';
      button.size = 'small';

      //Append front button to hotspot div
      element.appendChild(button);

      //Open outside icon
      const openIcon = document.createElement('ion-icon');
      openIcon.name = 'open-outline';
      openIcon.slot = 'start';

      //Append open outside icon to button to show user it will open in outside of the site
      button.appendChild(openIcon);


      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      button.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else {
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.className = 'hotspot__icon';

      element.appendChild(icon);
    }

    if (hotspot.typeId === '5fb3ebe8699373648481be88') { //if placeExperience hotspot is written-infospot
      const explanation = document.createElement('p');
      explanation.innerHTML = hotspot.content;
      explanation.className = 'explanation';

      element.appendChild(explanation);
    }

    element.onclick = ($event) => {
      this.hotspotClick($event, hotspot.id);
    };

    element.onmouseover = ($event) => {
      this.hotspotMouseover($event, hotspot.id);
    };

    var object = new CSS3DObject(element);
    object.name = hotspot.id;
    object.position.x = intersects[0].point.x;
    object.position.y = intersects[0].point.y;
    object.position.z = intersects[0].point.z;
    this.scene.add(object);

    hotspot.coordinate.xp = object.position.x;
    hotspot.coordinate.yp = object.position.y;
    hotspot.coordinate.zp = object.position.z;

    hotspot.coordinate.xr = object.rotation.x;
    hotspot.coordinate.yr = object.rotation.y;
    hotspot.coordinate.zr = object.rotation.z;

    console.log('Hotspot: ', hotspot);

    this.scenes[this.index].hotspots.push(hotspot);

    this.isContextmenuOpen = false;
  }

  createHotspotN() {
    var ObjectID = require('bson-objectid');

    const hotspot = new Hotspot();
    hotspot.id = ObjectID().toString();

    this.mousePosition.x = (this.mouseDownX / this.renderer.domElement.clientWidth) * 2 - 1;
    this.mousePosition.y = -(this.mouseDownY / this.renderer.domElement.clientHeight) * 2 + 1;

    this.cube.updateMatrixWorld();
    this.rayCaster.setFromCamera(this.mousePosition, this.camera);

    var intersects = this.rayCaster.intersectObject(this.scene.getObjectByName('prism_Obj'), true);

    hotspot.coordinate.xp = intersects[0].point.x;
    hotspot.coordinate.yp = intersects[0].point.y;
    hotspot.coordinate.zp = intersects[0].point.z;

    this.scenes[this.index].hotspots.push(hotspot);

    this.isContextmenuOpen = false;
  }

  async removeHotspot(i: number) {
    try {

      const alert = await this.alertController.create({
        header: 'Önemli!',
        message: 'Seçilen etkileşim alanını <strong>silmek</strong> silmek istediğinize emin misiniz?',
        buttons: [
          {
            text: 'Vazgeç',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {

            }
          }, {
            text: 'Sil',
            cssClass: 'danger',
            handler: () => {
              const object = this.scene.getObjectByName(this.scenes[this.index].hotspots[i].id) as CSS3DObject;
              this.scene.remove(object);
              this.scenes[this.index].hotspots.splice(i, 1);
            }
          }
        ]
      });

      await alert.present();


    } catch (error) {

    }
  }

  renderHotspot(hotspot: Hotspot) {
    var element = document.createElement('div');
    element.className = 'hotspot-point clickable ' + this.getHotspotClassName(hotspot.typeId);

    if (hotspot.typeId === '5fae8c9c699373648481be87') { //if hotspot type is scene-link

      const image = document.createElement('div');
      image.className = 'hotspot-point__img';

      const sceneName = document.createElement('span');
      const linkedScene = this.scenes.find(s => s.id === hotspot.sceneId);
      const id = linkedScene.coverId;

      sceneName.classList.add('scene-link__name');
      sceneName.innerText = linkedScene.name;

      element.appendChild(sceneName);

      this.securePipe.transform('https://api.yon.tc/visetra-api-v2/media/image/thumb/' + id).subscribe(
        (safeUrl: SafeUrl) => {
          //console.log("SafeUrl: " + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl))
          image.style.background = 'url(' + this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl) + ')';

        }
      );
      element.appendChild(image);

      element.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5ff58bb8699373648481bea2') { //if hotspot type is section-link

      const text = this.sections.find(s => s.id === hotspot.sectionId).name;
      //let link = hotspot.content.split(';')[1];

      //Button for front element
      const button = document.createElement('ion-button');
      button.id = 'bt-' + hotspot.id;
      button.innerText = text;
      button.size = 'default';
      button.fill = 'outline';
      button.color = 'white';
      //Append front button to hotspot div
      element.appendChild(button);

      //Open outside icon
      const openIcon = document.createElement('ion-icon');
      openIcon.name = 'arrow-forward-outline';
      openIcon.slot = 'end';

      //Append open outside icon to button to show user it will open in outside of the site
      button.appendChild(openIcon);


      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      button.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '604237fb699373648481bed0') { //if hotspot type is sections-menu
      //let text = this.sections.find(s => s.id===hotspot.sceneId).name;
      //let link = hotspot.content.split(';')[1];

      //Button for front element
      const button = document.createElement('ion-button');
      button.id = 'bt-' + hotspot.id;
      button.innerText = 'Bölümler';
      button.size = 'default';
      button.fill = 'outline';
      button.color = 'white';
      //Append front button to hotspot div
      element.appendChild(button);

      //Open outside icon
      const openIcon = document.createElement('ion-icon');
      openIcon.name = 'menu-outline';
      openIcon.slot = 'icon-only';

      //Append open outside icon to button to show user it will open in outside of the site
      button.appendChild(openIcon);


      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      button.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '60423833699373648481bed1') { //if hotspot type is info-panel

      //Button for front element
      const button = document.createElement('ion-button');
      button.id = 'bt-' + hotspot.id;
      //button.innerText = "Panel";
      button.size = 'large';
      button.fill = 'clear';
      button.color = 'white';
      //Append front button to hotspot div
      element.appendChild(button);

      //Open outside icon
      const openIcon = document.createElement('ion-icon');
      //openIcon.name = "information-outline";
      openIcon.src = 'assets/icons/info-filled.svg';
      openIcon.slot = 'icon-only';

      //Append open outside icon to button to show user it will open in outside of the site
      button.appendChild(openIcon);

      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      button.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5fb3ec02699373648481be89') { //if hotspot type is video-infospot
      //Icon for front element
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.id = 'ic-' + hotspot.id;
      icon.className = 'hotspot__icon';

      //Append front icon to hotspot div
      element.appendChild(icon);

      //iframe container to contain iframe and close button
      const iframeCont = document.createElement('div');
      iframeCont.id = hotspot.id;
      iframeCont.className = 'hotspot__media-container';

      //Append iframe container to hotspot div
      element.appendChild(iframeCont);

      //Button to contain close icon and to close
      const button = document.createElement('ion-button');
      button.className = 'hotspot__media-close';
      button.fill = 'clear';

      //Close icon
      const closeIcon = document.createElement('ion-icon');
      closeIcon.name = 'close-outline';
      closeIcon.slot = 'icon-only';

      //Set onclick event of close button to mediaClose function
      button.onclick = ($event) => {
        this.mediaClose($event, hotspot);
      };

      //Append close icon to close button
      button.appendChild(closeIcon);
      //Append close button to iframe container
      iframeCont.appendChild(button);

      //iframe to show video
      const iframe = document.createElement('iframe');
      iframe.className = 'hotspot__video';
      iframe.src = hotspot.content;
      iframe.setAttribute('allowfullscreen', '');
      iframe.setAttribute('frameborder', '0');

      //Append iframe to iframe container
      iframeCont.appendChild(iframe);

      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      icon.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5fb3ec53699373648481be8b') { //if hotspot type is photo-infospot
      //Icon for front element
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.id = 'ic-' + hotspot.id;
      icon.className = 'hotspot__icon';

      //Append front icon to hotspot div
      element.appendChild(icon);

      //iframe container to contain iframe and close button
      const imgCont = document.createElement('div');
      imgCont.id = hotspot.id;
      imgCont.className = 'hotspot__media-container';

      //Append img container to hotspot div
      element.appendChild(imgCont);

      //Button to contain close icon and to close
      const button = document.createElement('ion-button');
      button.className = 'hotspot__media-close';
      button.fill = 'clear';

      //Close icon
      const closeIcon = document.createElement('ion-icon');
      closeIcon.name = 'close-outline';
      closeIcon.slot = 'icon-only';

      //Set onclick event of close button to mediaClose function
      button.onclick = ($event) => {
        this.mediaClose($event, hotspot);
      };

      //Append close icon to close button
      button.appendChild(closeIcon);
      //Append close button to iframe container
      imgCont.appendChild(button);

      //img to show image
      const img = document.createElement('img');
      img.src = hotspot.content;
      img.className = 'hotspot__photo';

      //Append img to img container
      imgCont.appendChild(img);

      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      icon.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else if (hotspot.typeId === '5fb3ec33699373648481be8a') { //if hotspot type is link-infospot

      const text = hotspot.content.split(';')[0];
      const link = hotspot.content.split(';')[1];

      //Button for front element
      const button = document.createElement('ion-button');
      button.id = 'bt-' + hotspot.id;
      button.innerText = text;
      button.href = link;
      button.target = '_blank';
      button.size = 'small';

      //Append front button to hotspot div
      element.appendChild(button);

      //Open outside icon
      const openIcon = document.createElement('ion-icon');
      openIcon.name = 'open-outline';
      openIcon.slot = 'start';

      //Append open outside icon to button to show user it will open in outside of the site
      button.appendChild(openIcon);


      //Set onclick event of hotspot fron icon to hotspotClick function
      //hotspotClick function is to do things when hotspot clicked
      button.onclick = ($event) => {
        this.hotspotClick($event, hotspot);
      };
    }
    else {
      const icon = document.createElement('ion-icon');
      icon.name = this.getIconName(hotspot.typeId);
      icon.className = 'hotspot__icon';

      element.appendChild(icon);
    }

    if (hotspot.typeId === '5fb3ebe8699373648481be88') { //if placeExperience hotspot is written-infospot
      const explanation = document.createElement('p');
      explanation.innerHTML = hotspot.content;
      explanation.className = 'explanation';

      element.appendChild(explanation);
    }

    element.onclick = ($event) => {
      this.hotspotClick($event, hotspot.id);
    };

    element.onmouseover = ($event) => {
      this.hotspotMouseover($event, hotspot.id);
    };

    var object = new CSS3DObject(element);
    object.name = hotspot.id;
    object.position.x = hotspot.coordinate.xp;
    object.position.y = hotspot.coordinate.yp;
    object.position.z = hotspot.coordinate.zp;

    //console.info("Hotspot on render func: ", hotspot)
    if (hotspot.coordinate.xr !== null && hotspot.coordinate.yr !== null && hotspot.coordinate.zr !== null) {
      object.rotation.x = hotspot.coordinate.xr;
      object.rotation.y = hotspot.coordinate.yr;
      object.rotation.z = hotspot.coordinate.zr;
    }
    else {
      hotspot.coordinate.xr = object.rotation.x;
      hotspot.coordinate.yr = object.rotation.y;
      hotspot.coordinate.zr = object.rotation.z;
    }
    this.scene.add(object);

  }

  hotspotClick(e, id) {
    this.expandItem(this.scenes[this.index].hotspots.filter(h => h.id === id)[0]);
  }

  hotspotMouseover(e, id) {

    // let hotspot = this.scenes[this.index].hotspots.filter(h => h.id===id)[0];
    // let object = this.scene.getObjectByName(id) as CSS3DObject;

    // if (hotspot.type.key==="written-infospot") {
    //   let label = document.createElement("ion-label");
    //   label.innerHTML = hotspot.content;
    //   label.className = "written-infospot";
    // }

  }

  changeObjectCoordinate(hotspot: Hotspot) {

    if (this.scene !== undefined) {
      const object = this.scene.getObjectByName(hotspot.id);
      object.position.x = hotspot.coordinate.xp;
      object.position.y = hotspot.coordinate.yp;
      object.position.z = hotspot.coordinate.zp;

      object.rotation.x = hotspot.coordinate.xr;
      object.rotation.y = hotspot.coordinate.yr;
      object.rotation.z = hotspot.coordinate.zr;
    }
    //console.log(object)

  }

  listTypes() {
    this.typeProvider.listByClass('Hotspot').subscribe(
      (response: Response) => {
        if (response && response.status) {
          this.types = response.data;

          this.coreTypes = this.types.filter(t => t.type === null && t.relevants.filter(r => r.key === this.type.key).length > 0);

          // console.info("Type: ", this.type)
          // console.info("coreTypes: ", this.coreTypes)

          this.infospotTypes = this.types.filter(t => t.type !== null);
        }
      },
      (err) => {

      }
    );
  }

  selectType($event, hotspot: Hotspot) {

    const type: Type = this.types.filter(t => t.id === $event.target.value)[0];
    hotspot.typeId = type.id;
    this.scenes[this.index].hotspots.filter(h => h.id === hotspot.id)[0].typeId = type.id;

    if (type.key !== 'infospot' && type.key !== 'scene-link' && type.key !== 'section-link') {
      //Remove Hotspot
      const object = this.scene.getObjectByName(hotspot.id) as CSS3DObject;
      if (object !== undefined) {
        this.scene.remove(object);
      }

      //renderHotspot
      this.renderHotspot(hotspot);
    }
    // hotspot.type = this.types.filter(t => t.id===$event.target.value)[0];
    // this.changeIcon(hotspot);
  }

  selectScene($event, hotspot: Hotspot) {

    //Remove Hotspot
    const object = this.scene.getObjectByName(hotspot.id) as CSS3DObject;
    if (object !== undefined) {
      this.scene.remove(object);
    }
    const scene = this.scenes.filter(s => s.id === $event.target.value)[0];
    hotspot.sceneId = scene.id;
    this.selectedScene = scene.name;

    //renderHotspot
    this.renderHotspot(hotspot);
  }

  selectSection($event, hotspot: Hotspot) {

    //Remove Hotspot
    const object = this.scene.getObjectByName(hotspot.id) as CSS3DObject;
    if (object !== undefined) {
      this.scene.remove(object);
    }
    const section = this.sections.filter(s => s.id === $event.target.value)[0];
    hotspot.sectionId = section.id;
    //this.selectedScene = section.name;

    //renderHotspot
    this.renderHotspot(hotspot);
  }

  changeIcon(hotspot: Hotspot) {
    if (this.scene !== undefined) {
      const object = this.scene.getObjectByName(hotspot.id) as CSS3DObject;
      (object.element.children[0] as HTMLIonIconElement).name = this.getIconName(hotspot.typeId);
    }
  }

  getIconName(id): string {
    switch (id) {
      case '5fae8c9c699373648481be87': //"scene-link":
        return 'arrow-up-outline';
      case '5fb3ec02699373648481be89'://"video-infospot":
        return 'play-outline';
      case '5fb3ec53699373648481be8b'://"photo-infospot":
        return 'image-outline';
      case '5fb3ec33699373648481be8a'://"link-infospot":
        return 'link-outline';
      case '5fb3ebe8699373648481be88'://"written-infospot":
        return 'information-outline';
    }
  }

  getHotspotClassName(id) {
    switch (id) {
      case '5fae8c9c699373648481be87': //"scene-link":
        return 'scene-link';
      case '5fb3ec02699373648481be89'://"video-infospot":
        return 'video-infospot';
      case '5fb3ec53699373648481be8b'://"photo-infospot":
        return 'photo-infospot';
      case '5fb3ec33699373648481be8a'://"link-infospot":
        return 'link-infospot';
      case '5fb3ebe8699373648481be88'://"written-infospot":
        return 'written-infospot';
      case '604237fb699373648481bed0'://"sections-menu":
        return 'sections-menu';
      case '5ff58bb8699373648481bea2'://"section-link":
        return 'section-link';
      case '60423833699373648481bed1'://"info-panel":
        return 'info-panel';
    }
  }

  getContent() {
    if (this.scenes[this.index].contentTypeId === '60a7a68a699373648481befe') { //If content is Image
      this.securePipe.transform(MEDIA_URL + this.scenes[this.index].contentId).subscribe(
        (safeUrl: SafeUrl) => {
          this.scenes[this.index].source =
            this.texture = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeUrl);

          //this.createScene(this.rendererCanvas);
          if (!this.scene) {
            this.createScene2(this.rendererCanvas, 1);
            this.animate();
          }
          else {
            const texture = new THREE.TextureLoader().load(this.texture);
            this.material.map = texture;
          }
          this.placeHotspots();

        });
    }

    else if (this.scenes[this.index].contentTypeId === '60a7a69b699373648481beff') { //If content is video
      this.fileProvider.getVideoUrl(this.scenes[this.index].contentId).subscribe(
        (response: Response) => {
          if (response && response.status) {
            this.texture = response.data['changingThisBreaksApplicationSecurity'];
            // this.createScene(this.rendererCanvas);
            if (!this.scene) {
              this.createScene2(this.rendererCanvas, 1);
              this.animate();
            }
            else {
              this.video.nativeElement.src = this.texture;
              this.video.nativeElement.play();

              const texture = new VideoTexture(this.video.nativeElement);
              this.material.map = texture;
            }
            this.placeHotspots();
          }
        });
    }

  }

  createScene(canvas: ElementRef<HTMLCanvasElement>): void {
    // The first step is to get the reference of the canvas element from our HTML document
    this.canvas = canvas.nativeElement;

    //create webgl renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,    // transparent background
      antialias: true // smooth edges
    });

    this.renderer.setSize(window.innerWidth, window.innerHeight);

    //create css3d renderer
    this.css3DRenderer = new CSS3DRenderer();
    this.cssRendererContainer.nativeElement.prepend(this.css3DRenderer.domElement);
    this.css3DRenderer.setSize(window.innerWidth, window.innerHeight);

    //create mouse position
    this.mousePosition = new THREE.Vector2();

    //create ray caster
    this.rayCaster = new THREE.Raycaster();

    // create the scene
    this.scene = new THREE.Scene();

    //create camera
    this.camera = new THREE.PerspectiveCamera(
      60, window.innerWidth / window.innerHeight, 1, 1100
    );
    this.camera.position.z = 20;

    //add camera to scene
    this.scene.add(this.camera);

    // soft white light
    this.light = new THREE.AmbientLight(0x404040);
    this.light.position.z = 10;
    this.scene.add(this.light);

    var geometry = new THREE.SphereBufferGeometry(500, 60, 40);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(- 1, 1, 1);

    var texture = new THREE.TextureLoader().load(this.texture);
    this.material = new THREE.MeshBasicMaterial({ map: texture });

    this.cube = new THREE.Mesh(geometry, this.material);
    this.cube.name = 'prism_Obj';
    this.scene.add(this.cube);
  }

  createScene2(canvas: ElementRef<HTMLCanvasElement>, mode: number): void {
    try {

      // The first step is to get the reference of the canvas element from our HTML document
      this.canvas = canvas.nativeElement;

      //create webgl renderer
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.canvas,
        alpha: true,    // transparent background
        antialias: true // smooth edges
      });

      //alert("Window Inner Width: " + window.innerWidth + "\n" + "Window Inner Height: " + window.innerHeight)

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      //this.renderer.setSize(1920, 1080);

      //create css3d renderer
      this.css3DRenderer = new CSS3DRenderer();
      this.cssRendererContainer.nativeElement.prepend(this.css3DRenderer.domElement);
      this.css3DRenderer.setSize(window.innerWidth, window.innerHeight);

      //create mouse position
      this.mousePosition = new THREE.Vector2();

      //create ray caster
      this.rayCaster = new THREE.Raycaster();

      // create the scene
      this.scene = new THREE.Scene();

      //create camera
      this.camera = new THREE.PerspectiveCamera(
        60, window.innerWidth / window.innerHeight, 1, 1100
      );
      this.camera.position.set(0, 0, 0).normalize().negate();
      this.camera.position.z = 20;
      //add camera to scene
      this.scene.add(this.camera);

      // soft white light
      this.light = new THREE.AmbientLight(0x404040);
      this.light.position.z = 10;
      this.scene.add(this.light);

      var geometry = new THREE.SphereBufferGeometry(500, 60, 40);
      // invert the geometry on the x-axis so that all of the faces point inward
      geometry.scale(- 1, 1, 1);
      var texture;

      if (mode === 1) { //if content is image
        texture = new THREE.TextureLoader().load(this.texture);
      }
      else if (mode === 2) { //if content is video
        this.video.nativeElement.src = this.texture;
        this.video.nativeElement.play();

        texture = new VideoTexture(this.video.nativeElement);
      }

      this.material = new THREE.MeshBasicMaterial({ map: texture });
      this.material.map.minFilter = THREE.LinearFilter;
      //this.material.map.magFilter = THREE.LinearMipMapNearestFilter;

      this.cube = new THREE.Mesh(geometry, this.material);
      this.cube.name = 'prism_Obj';
      this.scene.add(this.cube);

      //this.controls = new OrbitControls(this.camera, canvas.nativeElement);

      this.controls = new OrbitControls(this.camera, this.rendererCanvas.nativeElement);
      // this.controls.enableDamping = true;
      // this.controls.dampingFactor = 0.07;

      this.controls.addEventListener('change', () => {
        this.controls.update();

        this.renderer.render(this.scene, this.camera);
        this.css3DRenderer.render(this.scene, this.camera);
      });

      this.camera.updateProjectionMatrix();

      //this.createControls();

    } catch (e) {

    }
  }

  finish() {
    // this.scenes[this.index].hotspots.forEach(h => {
    //   h.coordinate = {
    //     xP: h.coordinate.xP,
    //     yP: h.coordinate.yP,
    //     zP: h.coordinate.zP,
    //     xR: h.coordinate.xR,
    //     yR: h.coordinate.yR,
    //     zR: h.coordinate.zR
    //   }
    // });
    // this.scenes[this.index].hotspots.forEach(h => {
    //   h.expanded = undefined;
    // });
    this.modalController.dismiss(this.returnAll ? this.scenes : this.scenes[this.index]);
  }

  animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.render();
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.render();
        });
      }

      window.addEventListener('resize', () => {
        this.resize();
      });
    });

    this.update();
  }

  render(): void {
    this.frameId = requestAnimationFrame(() => {
      this.render();
    });

    // this.cube.rotation.x += 0.01;
    // this.cube.rotation.y += 0.01;

    // this.cube.rotation.x = this.lat / 70;
    // this.cube.rotation.y = this.lon / 70;

    // this.camera.rotation.x = -1 * this.lat / 70;
    // this.camera.rotation.y = -1 * this.lon / 70;


    this.lat = Math.max(-85, Math.min(85, this.lat));
    this.phi = THREE.MathUtils.degToRad(90 - this.lat);
    this.theta = THREE.MathUtils.degToRad(this.lon);
    this.camera.position.x = 100 * Math.sin(this.phi) * Math.cos(this.theta);
    this.camera.position.y = 100 * Math.cos(this.phi);
    this.camera.position.z = 100 * Math.sin(this.phi) * Math.sin(this.theta);

    this.camera.lookAt(this.scene.position);

    this.renderer.render(this.scene, this.camera);
    this.css3DRenderer.render(this.scene, this.camera);

  }

  update() {
    this.renderer.render(this.scene, this.camera);
    this.css3DRenderer.render(this.scene, this.camera);
  }

  resize(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
    this.css3DRenderer.setSize(width, height);

  }

  onPointerStart(event) {
    this.isContextmenuOpen = false;
    this.isUserInteracting = true;

    var clientX = event.clientX || event.touches[0].clientX;
    var clientY = event.clientY || event.touches[0].clientY;

    this.onMouseDownMouseX = clientX;
    this.onMouseDownMouseY = clientY;

    this.onMouseDownLon = this.lon;
    this.onMouseDownLat = this.lat;

    this.mousePosition.x = (event.clientX / this.renderer.domElement.clientWidth) * 2 - 1;
    this.mousePosition.y = -(event.clientY / this.renderer.domElement.clientHeight) * 2 + 1;

    this.cube.updateMatrixWorld();
    this.rayCaster.setFromCamera(this.mousePosition, this.camera);

    var intersects = this.rayCaster.intersectObject(this.scene.getObjectByName('prism_Obj'), true);

    console.log('Points: ', intersects[0].point);

  }

  onPointerMove(event) {
    if (this.isUserInteracting === true) {
      var clientX = event.clientX || event.touches[0].clientX;
      var clientY = event.clientY || event.touches[0].clientY;

      this.lon = (this.onMouseDownMouseX - clientX) * 0.1 + this.onMouseDownLon;
      this.lat = (this.onMouseDownMouseY - clientY) * 0.1 + this.onMouseDownLat;
    }
  }

  onPointerUp() {
    this.isUserInteracting = false;
  }

  onDocumentMouseWheel(event) {
    var fov = this.camera.fov + event.deltaY * 0.04;

    this.camera.fov = THREE.MathUtils.clamp(fov, 35, 75);
    this.camera.updateProjectionMatrix();
  }

  openContextmenu(e) {
    this.isContextmenuOpen = true;
    this.mouseDownX = e.clientX;
    this.mouseDownY = e.clientY;
  }

  @HostListener('resize')
  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.css3DRenderer.setSize(window.innerWidth, window.innerHeight);
  }

  mediaClose(e, hotspot: Hotspot) {
    document.getElementById(hotspot.id).className = 'hotspot__media-container';
    document.getElementById('ic-' + hotspot.id).className = 'hotspot__icon ios hydrated';
  }

  expandItem(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      this.scenes[this.index].hotspots.map(listItem => {
        if (item === listItem) {
          listItem['expanded'] = !listItem['expanded'];
        } else {
          listItem['expanded'] = false;
        }
        return listItem;
      });
    }
  }
  // @HostListener('mousedown')
  // @HostListener('touchstart')
  // universalOnPointerUp() {
  //   if (this.isContextmenuOpen) {
  //     this.isContextmenuOpen = false;
  //   }
  // }
}
