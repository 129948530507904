import { Request } from 'src/app/domain/base/request';
import { Type } from '../type';

export class UpdateTypeRequest extends Request {
  type: Type;

  constructor(type: Type) {
    super();
    this.type = type;
  }
}
