import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sceneFilter'
})

@Injectable()
export class SceneFilterPipe implements PipeTransform {
    transform(value: any[], id, args?): string {
        if (value.filter(s => s.id === id)[0] !== undefined) {
            return value.filter(s => s.id === id)[0].name;
        }
    }
};
