import { ExpandableModule } from 'src/app/components/expandable/expandable.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HotspotsPageRoutingModule } from './hotspots-routing.module';

import { HotspotsModal } from './hotspots.modal';
import { TypePipeModule } from '../../pipes/type-pipe/type.pipe.module';
import { SecurePipeModule } from 'src/app/pipes/secure/secure.module';
import { ContextmenuModule } from 'src/app/components/contextmenu/contextmenu.module';
import { SceneFilterPipeModule } from 'src/app/pipes/scene-filter/scene-filter.pipe.module';
import { SectionFilterPipeModule } from 'src/app/pipes/section-filter/section-filter.pipe.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HotspotsPageRoutingModule,
    ContextmenuModule,
    ExpandableModule,
    SceneFilterPipeModule,
    SectionFilterPipeModule,
    TypePipeModule,
    SecurePipeModule,
    SwiperModule
  ],
  declarations: [HotspotsModal]
})
export class HotspotsModalModule {}
