import { SecurePipe } from './secure.pipe';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  declarations: [SecurePipe],
  exports: [SecurePipe],
  providers: [SecurePipe]
})

export class SecurePipeModule { }
